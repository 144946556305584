import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import Loader from "./Loader";

const cookies = new Cookies();
const styles = {
  btngrad: {
    backgroundImage: 'linear-gradient(to right, #3D7EAA 0%, #FFE47A  51%, #3D7EAA  100%)',
    margin: '10px',
    padding: '15px 45px',
    textAlign: 'center',
    textTransform: 'uppercase',
    transition: '0.5s',
    backgroundSize: '200% auto',
    color: 'white',
    boxShadow: '0 0 20px #eee',
    borderRadius: '10px',
    display: 'block',
    fontSize: '16px',
    border: 'none',
    cursor: 'pointer',
    fontWeight: '600'
  }
}
export const Banner = (props) => {
  const [refresh, setrefresh] = useState(true);
  const [sitename, setSetSiteName] = useState("");
  const [banner, setBanner] = useState(0);
  const [updatebanner, setupdatebanner] = useState(false);
  const [postImage, setPostImage] = useState({
    myFile: "",
  });

  const url = "https://fair247.vip/api4/uploadimg";

  const createImage = (newImage) => axios.post(url, {
    site: sitename,
    banner: banner,
    image: newImage.myFile,
    image_extension: "jpeg"
  }).then(result => {
    if (result.status === 200) {

      toast.success(`Banner${banner} uploaded successfully on ${sitename} site`, { position: toast.POSITION.TOP_CENTER });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      setSetSiteName('');
      setBanner(0);
      setPostImage({ myFile: "" })
    }
  }).catch(err => {
    toast.error(`Oops something goes wrong, please try later`, { position: toast.POSITION.TOP_CENTER });
  });
  const createPost = async (post) => {
    try {
      await createImage(post);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (banner === "" || sitename === "") {
      return toast.error(`Please choose site name and banner position..!!`, { position: toast.POSITION.TOP_CENTER });
    }
    createPost(postImage);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setPostImage({ ...postImage, myFile: base64 });
  };

  console.log(postImage);
  return (
    <React.Fragment>
      <div className="biab_body biab_fluid biab_account-page" id="biab_body">
        <div className="biab_wrapper js-wrapper" style={{ width: 1347 }}>
          <div
            id="biab_message-dialog"
            className="biab_message-dialog-wrapper"
          />
          <div className="biab_page-wrapper">
            <div
              className="biab_page-holder"
              style={{ margin: "-28px 0px -46px" }}
            >
              <div className="biab_page-container">
                <div className="js-scroll-start" style={{ margin: 28 }} />
                <div
                  className="biab_scrollable js-scrollable"
                  style={{ position: "relative", overflow: "auto" }}
                >
                  <div
                    className="biab_scrollable-content js-scrollable-content"
                    style={{ marginBottom: 60 }}
                  >
                    <div style={{ marginBottom: 20, marginLeft: 0 }}>
                      <div
                        style={{
                          height: 30,
                          width: "100%",
                          backgroundColor: "rgb(0 76 116)",
                          color: "white",
                          fontSize: 15,
                          fontWeight: "bold",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          paddingLeft: 10,
                        }}
                      >
                        Banner Upload
                      </div>
                      <div
                        style={{
                          height: 'auto',
                          width: "100%",
                          backgroundColor: "#353434",
                          color: "gray",
                          fontSize: 15,
                          fontWeight: "bold",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            margin: "15px",
                            width: "50%",
                            display: "block",
                          }}
                        >
                          <input
                            type="radio"
                            value="NOYABETADMIN"
                            name="site"
                            onClick={() => setSetSiteName("NOYABETADMIN")}
                          />{" "}
                          Noyabet Admin <br />
                          <input
                            type="radio"
                            value="LEBUZZADMIN"
                            name="site"
                            style={{ marginTop: "10px" }}
                            onClick={() => setSetSiteName("LEBUZZADMIN")}
                          />{" "}
                          Lebuzz Admin <br />
                          <input
                            type="radio"
                            value="BAZIGARADMIN"
                            name="site"
                            style={{ marginTop: "10px" }}
                            onClick={() => setSetSiteName("BAZIGARADMIN")}
                          />{" "}
                          Bazigar Admin <br />
                          <input
                            type="radio"
                            value="VELLKIADMIN"
                            name="site"
                            style={{ marginTop: "10px" }}
                            onClick={() => setSetSiteName("VELLKIADMIN")}
                          />{" "}
                          Vellki Admin <br />
                          <input
                            type="radio"
                            value="VELLKIPROADMIN"
                            name="site"
                            style={{ marginTop: "10px" }}
                            onClick={() => setSetSiteName("VELLKIPROADMIN")}
                          />{" "}
                          Vellki-Pro Admin <br />
                          <input
                            type="radio"
                            value="9XPLAYADMIN"
                            name="site"
                            style={{ marginTop: "10px" }}
                            onClick={() => setSetSiteName("9XPLAYADMIN")}
                          />{" "}
                          9XPlay Admin <br />
                          <input
                            type="radio"
                            value="PLAYBUZADMIN"
                            name="site"
                            style={{ marginTop: "10px" }}
                            onClick={() => setSetSiteName("PLAYBUZADMIN")}
                          />{" "}
                          Playbuzz Admin <br />
                          <input
                            type="radio"
                            value="LUCKADMIN"
                            name="site"
                            style={{ marginTop: "10px" }}
                            onClick={() => setSetSiteName("LUCKADMIN")}
                          />{" "}
                          Luck Admin <br />
                        </div>
                        <div
                          style={{
                            margin: "15px",
                            width: "50%",
                            display: "block",
                          }}
                        >
                          <input
                            type="radio"
                            value="1"
                            name="banner"
                            onClick={() => setBanner(1)}
                          />{" "}
                          Banner 1 <br />
                          <input
                            type="radio"
                            value="2"
                            name="banner"
                            onClick={() => setBanner(2)}
                            style={{ marginTop: "10px" }}
                          />{" "}
                          Banner 2 <br />
                          <input
                            type="radio"
                            value="3"
                            name="banner"
                            onClick={() => setBanner(3)}
                            style={{ marginTop: "10px" }}
                          />{" "}
                          Banner 3 <br />
                          <input
                            type="radio"
                            value="4"
                            name="banner"
                            onClick={() => setBanner(4)}
                            style={{ marginTop: "10px" }}
                          />{" "}
                          Banner 4 <br />
                          <input
                            type="radio"
                            value="5"
                            name="banner"
                            onClick={() => setBanner(5)}
                            style={{ marginTop: "10px" }}
                          />{" "}
                          Banner 5 <br />
                          <input
                            type="radio"
                            value="6"
                            name="banner"
                            onClick={() => setBanner(6)}
                            style={{ marginTop: "10px" }}
                          />{" "}
                          Banner 6<br />
                          <input
                            type="radio"
                            value="7"
                            name="banner"
                            onClick={() => setBanner(7)}
                            style={{ marginTop: "10px" }}
                          />{" "}
                          Banner 7<br />
                          <input
                            type="radio"
                            value="8"
                            name="banner"
                            onClick={() => setBanner(8)}
                            style={{ marginTop: "10px" }}
                          />{" "}
                          Banner 8<br />
                          <input
                            type="radio"
                            value="9"
                            name="banner"
                            onClick={() => setBanner(9)}
                            style={{ marginTop: "10px" }}
                          />{" "}
                          Banner 9<br />
                          <input
                            type="radio"
                            value="10"
                            name="banner"
                            onClick={() => setBanner(10)}
                            style={{ marginTop: "10px" }}
                          />{" "}
                          Banner 10<br />
                        </div>
                        <div
                          style={{
                            margin: "15px",
                            width: "100%",
                            display: "block",
                          }}
                        >
                          <img
                            src={postImage.myFile}
                            style={{ width: "100%" }}
                          />
                          <input
                            type="file"
                            label="Image"
                            name="myFile"
                            accept=".jpeg, .png, .jpg"
                            onChange={(e) => handleFileUpload(e)}
                          />
                          <br />
                          <button
                            type="button"
                            onClick={(e) => handleSubmit(e)}
                            style={styles.btngrad}
                          >Post Banner</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
