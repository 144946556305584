import React, { useState } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import { toast } from 'react-toastify';

const cookies = new Cookies();

toast.configure()

export const OddLimitPopup = (props) => {
    const [tvId, setTvId] = useState('');

    const addTvId = () => {
        var ssid = cookies.get('sid');
        axios.post('https://fair247.vip/api4/updatetvId', {
            sid: ssid,
            tvId,
            eventId: props.eventId
        }).then(result => {
            if (result.status === 200) {
                props.OddLimitClose();
                toast.success('Tv Id Add Successfully!!', { position: toast.POSITION.TOP_CENTER })
            }
            else {
                toast.warn('Opps, Somthings wents Wrong!!!', { position: toast.POSITION.TOP_CENTER })
            }

        }).catch(e => { });
    }

    return (
        <React.Fragment>
            <body class="biab_fluid_body biab_desktop">
                <div class="biab_body biab_fluid" id="biab_body">
                    <div class="biab_heads-up-over biab_hidden" id="biab_headsUpOver">

                    </div>
                    <div className="biab_modal biab_modal-wrapper biab_fade js-modal biab_in" tabIndex={-1} role="dialog" style={{ display: 'block' }}>
                        <div className="biab_modal-dialog" id="dialogpop">
                            <div className="biab_modal-content js-modal-content">
                                <div className="biab_modal-header js-modal-header">
                                    <button onClick={() => { props.OddLimitClose(false) }} type="button" className="biab_close js-close" data-dismiss="modal" aria-label="Close">
                                        <i class="fas fa-times"></i></button>
                                    <h4 className="biab_modal-title">TvId</h4>
                                </div>
                                <div className="biab_modal-body biab_rules-modal-body js-modal-body" style={{ height: "71px" }} >
                                    <div class="form-group"><label class="col-sm-2 control-label">TvId</label>
                                        <div class="search-wrap" id="userSearchUl"><div>
                                            <input class="search-input" type="text" name="userId" id="userId" placeholder="tvId" value={tvId} onChange={e => setTvId(e.target.value)} />
                                            <button class="search-but" id="searchUserId" onClick={addTvId}>Update</button>
                                        </div>
                                        </div>
                                    </div>

                                </div>
                            </div></div>
                        <div className="biab_modal-backdrop biab_fade biab_in js-backdrop" /></div>
                </div>
            </body>
        </React.Fragment>
    )
}
